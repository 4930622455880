const MONTHS = [
  "Januar",
  "Februar",
  "Marts",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "December",
];

export default {
  monthnameFromIndex(index) {
    return MONTHS[index];
  },
  indexFromMonthname(name) {
    return MONTHS.indexOf(name);
  },
  yearmonthstringToYearMonth(yearmonth) {
    const split = yearmonth.split("-");
    return [parseInt(split[0]), parseInt(split[1]) - 1]; // Subtract 1 to make it zero-indexed
  },
  yearMonthToYearmonthstring(year, month) {
    const oneIndexedMonth = month + 1;
    const formattedMonth =
      oneIndexedMonth < 10
        ? "0" + oneIndexedMonth.toString()
        : oneIndexedMonth.toString();

    return `${year.toString()}-${formattedMonth}`;
  },
};
